import React, { useContext } from "react";
import HomeHero from "../images/Heros/Home-Hero.jpg";
import { PlayingCardDeck, Layout, NitehawkPromoVid, Seo } from "../components";
import ProductContext from "../context/ProductContext";

export default function IndexPage() {
	// Initially set this object structure up to make it easier to load up pages with stuff. Object shape changes a bit, but it should all pass down as needed.

	//TODO: Check all the mobile views and renders in google, 768px is the cut off for hover effects :(
	const { collections } = useContext(ProductContext);

	const collection2Display = collections.filter(({ title }) => {
		if (title !== "Paintball Specials" && title !== "Airsoft Specials") {
			return title;
		}
		return "";
	});

	// Sorting the collections arrray to get it to show on the page like we want...
	const collectionsArray = ["Paintball", "Airsoft", "Gellyball"];
	const sorter = (a, b) => {
		return (
			collectionsArray.indexOf(a.title) - collectionsArray.indexOf(b.title)
		);
	};

	collection2Display.sort(sorter);

	return (
		<>
			<Layout
				heroImg={HomeHero}
				page='home'
				headerText='Nitehawk Gellyball'
				bodyText="The Inland Northwest's Premier Action Sports Park"
				btnText='Book a Game'
				btnRoute=''
				contentsDown=''
				defaultSport=''
				FAQHeaderImg=''
				FAQtexts={[]}
				altText=''>
				<Seo title='Home' description='NiteHawk homepage' />
				<NitehawkPromoVid
					videoSrcURL='https://www.youtube.com/embed/Wt1ErHx8sdI'
					videoTitle='Nitehawk Promotional Video'
				/>
				<PlayingCardDeck collections={collection2Display} />
			</Layout>
		</>
	);
}
